import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/404.gif'


const _hoisted_1 = { class: "http404-container" }
const _hoisted_2 = { class: "http404" }
const _hoisted_3 = { class: "bullshit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "pic-404" }, [
        _createElementVNode("img", {
          class: "pic-404__parent",
          src: _imports_0,
          alt: "404"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bullshit__oops" }, "非常抱歉!", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "bullshit__headline" }, "未找到您访问的页面", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "bullshit__info" }, "请确认访问的地址是否正确", -1)),
        _createElementVNode("a", {
          class: "bullshit__return-home",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args)))
        }, "去首页")
      ])
    ])
  ]))
}