import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/code.png'
import _imports_2 from '@/assets/beian_icon.0c577066.png'


const _hoisted_1 = { class: "contactWrap" }
const _hoisted_2 = { class: "footer" }
const _hoisted_3 = { class: "middle" }
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "centerMod" }
const _hoisted_6 = { class: "copyRight" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JoinUs = _resolveComponent("JoinUs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setJoinUs(true)))
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "leftMod" }, [
            _createElementVNode("img", {
              class: "logo",
              src: _imports_0
            }),
            _createElementVNode("dl", null, [
              _createElementVNode("dt", null, "公司地址"),
              _createElementVNode("dd", null, "上海市杨浦区政立路415号A座9楼")
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("dl", null, [
              _cache[4] || (_cache[4] = _createElementVNode("dt", null, "首页", -1)),
              _createElementVNode("dd", null, [
                _createVNode(_component_router_link, { to: "/index?hash=programme" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("解决方案")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("dd", null, [
                _createVNode(_component_router_link, { to: "/index?hash=partner" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("合作伙伴")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("dd", null, [
                _createVNode(_component_router_link, { to: "/index?hash=about" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("关于我们")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("dl", null, [
              _cache[7] || (_cache[7] = _createElementVNode("dt", null, "成功案例", -1)),
              _createElementVNode("dd", null, [
                _createVNode(_component_router_link, { to: "/cxample?hash=aa" }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("优秀案例")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("dd", null, [
                _createVNode(_component_router_link, { to: "/cxample?hash=bb" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("互动游戏")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("dl", null, [
              _cache[10] || (_cache[10] = _createElementVNode("dt", null, "关于我们", -1)),
              _createElementVNode("dd", null, [
                _createVNode(_component_router_link, { to: "/about?hash=partner" }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("合作伙伴")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("dd", null, [
                _createVNode(_component_router_link, { to: "/about?hash=about" }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode("联系我们")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "rightMod" }, [
            _createElementVNode("div", { class: "codeWrap" }, [
              _createElementVNode("img", { src: _imports_1 }),
              _createElementVNode("p", null, "咨询服务")
            ])
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "beian" }, [
            _createElementVNode("a", {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_bank"
            }, "沪ICP备2023008334号-1"),
            _createElementVNode("a", {
              href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002006134",
              target: "_bank"
            }, [
              _createElementVNode("img", {
                src: _imports_2,
                alt: ""
              }),
              _createTextVNode("沪公网安备 31011002006134")
            ])
          ], -1)),
          _createElementVNode("p", null, [
            _cache[13] || (_cache[13] = _createTextVNode("Copyright © 2006")),
            (_ctx.year)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, "-" + _toDisplayString(_ctx.year), 1))
              : _createCommentVNode("", true),
            _cache[14] || (_cache[14] = _createTextVNode(" 上海海湾汇信息科技有限公司"))
          ])
        ])
      ])
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createBlock(_component_JoinUs, {
          key: 0,
          closeFun: _ctx.setJoinUs
        }, null, 8, ["closeFun"]))
      : _createCommentVNode("", true)
  ], 64))
}