import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo1.png'


const _hoisted_1 = { class: "headWrap" }
const _hoisted_2 = { class: "middle" }
const _hoisted_3 = { class: "logo" }
const _hoisted_4 = { class: "menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JoinUs = _resolveComponent("JoinUs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "hiddenHeight" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, { to: "/index" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("img", { src: _imports_0 }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, {
              to: "/index",
              class: _normalizeClass({curr: _ctx.path == '/index'})
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("首页")
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, {
              to: "/cxample",
              class: _normalizeClass({curr: _ctx.path == '/cxample'})
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("成功案例")
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, {
              to: "/about",
              class: _normalizeClass({curr: _ctx.path == '/about'})
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("关于我们")
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              class: "btn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setJoinUs(true)))
            }, "申请免费使用")
          ])
        ])
      ]),
      (_ctx.isOpen)
        ? (_openBlock(), _createBlock(_component_JoinUs, {
            key: 0,
            closeFun: _ctx.setJoinUs
          }, null, 8, ["closeFun"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}