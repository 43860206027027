import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './img/cxample_01.png'
import _imports_1 from './img/cxample_02.png'
import _imports_2 from './img/cxample_03.png'
import _imports_3 from './img/cxample_04.png'
import _imports_4 from './img/cxample_05.png'
import _imports_5 from './img/cxample_06.png'
import _imports_6 from './img/cxample_07.png'
import _imports_7 from './img/cxample_08.png'
import _imports_8 from './img/cxample_09.png'
import _imports_9 from './img/cxample_10.png'
import _imports_10 from './img/cxample_11.png'
import _imports_11 from './img/cxample_12.png'
import _imports_12 from './img/cxample_13.png'
import _imports_13 from './img/cxample_14.png'
import _imports_14 from './img/cxample_15.png'
import _imports_15 from './img/cxample_16.png'
import _imports_16 from './img/cxample_17.png'
import _imports_17 from './img/cxample_18.png'
import _imports_18 from './img/cxample_19.png'
import _imports_19 from './img/cxample_20.png'


const _hoisted_1 = { class: "root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_myHead = _resolveComponent("myHead")!
  const _component_myFooter = _resolveComponent("myFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_myHead),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"bannerWrap\" data-v-1b91bee6><div class=\"middle\" data-v-1b91bee6><h4 data-v-1b91bee6>海量行业优秀案例</h4><p data-v-1b91bee6>支持一键复刻，商城快速开启</p></div></div><div class=\"cxampleWrap\" data-v-1b91bee6><div class=\"middle\" data-v-1b91bee6><dl class=\"cxample\" id=\"aa\" data-v-1b91bee6><dt data-v-1b91bee6>优秀案例</dt><dd data-v-1b91bee6><img src=\"" + _imports_0 + "\" data-v-1b91bee6><img src=\"" + _imports_1 + "\" data-v-1b91bee6><img src=\"" + _imports_2 + "\" data-v-1b91bee6><img src=\"" + _imports_3 + "\" data-v-1b91bee6><img src=\"" + _imports_4 + "\" data-v-1b91bee6><img src=\"" + _imports_5 + "\" data-v-1b91bee6><img src=\"" + _imports_6 + "\" data-v-1b91bee6><img src=\"" + _imports_7 + "\" data-v-1b91bee6><img src=\"" + _imports_8 + "\" data-v-1b91bee6><img src=\"" + _imports_9 + "\" data-v-1b91bee6><img src=\"" + _imports_10 + "\" data-v-1b91bee6><img src=\"" + _imports_11 + "\" data-v-1b91bee6></dd></dl><dl class=\"cxample\" id=\"bb\" data-v-1b91bee6><dt data-v-1b91bee6>互动游戏</dt><dd data-v-1b91bee6><img src=\"" + _imports_12 + "\" data-v-1b91bee6><img src=\"" + _imports_13 + "\" data-v-1b91bee6><img src=\"" + _imports_14 + "\" data-v-1b91bee6><img src=\"" + _imports_15 + "\" data-v-1b91bee6><img src=\"" + _imports_16 + "\" data-v-1b91bee6><img src=\"" + _imports_17 + "\" data-v-1b91bee6><img src=\"" + _imports_18 + "\" data-v-1b91bee6><img src=\"" + _imports_19 + "\" data-v-1b91bee6></dd></dl></div></div>", 2)),
    _createVNode(_component_myFooter)
  ]))
}