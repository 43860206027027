import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "joinUs",
  ref: "root"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_modal, {
      footer: null,
      getContainer: () => _ctx.$refs.root,
      width: 500,
      onCancel: _ctx.cancel,
      open: _ctx.open,
      "onUpdate:open": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.open) = $event)),
      title: "申请试用"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, _mergeProps({ model: _ctx.formState }, _ctx.layout, {
          name: "nest-messages",
          "validate-messages": _ctx.validateMessages,
          onFinish: _ctx.onFinish
        }), {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              name: "name",
              label: "姓名",
              rules: [{ required: true }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event)),
                  placeholder: "请输入您的姓名",
                  maxlength: 20
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              name: "company",
              label: "公司"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.company,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.company) = $event)),
                  placeholder: "请输入您的公司名称",
                  maxlength: 64
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              name: "phone",
              label: "手机号",
              rules: [{ required: true },{ pattern: /^1[3456789]{1}\d{9}$/, message:'请检查手机号格式' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.phone,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.phone) = $event)),
                  placeholder: "请输入您的手机号",
                  maxlength: 11
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              name: "mailbox",
              label: "邮箱"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.mailbox,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.mailbox) = $event)),
                  placeholder: "请输入您的邮箱",
                  maxlength: 50
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              name: "remark",
              label: "留言"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: _ctx.formState.remark,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.remark) = $event)),
                  placeholder: "请输入您的留言",
                  maxlength: 100
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  class: "submit",
                  "html-type": "submit"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("提交信息")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 16, ["model", "validate-messages", "onFinish"])
      ]),
      _: 1
    }, 8, ["getContainer", "onCancel", "open"])
  ], 512))
}