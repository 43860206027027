
import JoinUs from './joinUs.vue'
import { defineComponent, toRefs, reactive, onBeforeMount } from 'vue'
export default defineComponent({
  name:'Footer',
  components: {
    JoinUs
  },
  setup() {
    const state = reactive({
      isOpen: false,
      year: ''
    });
    const setJoinUs=(isOpen: boolean)=>{
      state.isOpen = isOpen
    }
      onBeforeMount(() => {
          const request = new XMLHttpRequest();
          request.open('GET', '/', true);
          request.send();
          request.onreadystatechange = () => {
              const serverTime = request.getResponseHeader('Date');
              if (serverTime) {
                  state.year = new Date(serverTime).getFullYear().toString();
              } else {
                  // 没网情况获取本地时间
                  state.year = new Date().getFullYear().toString();
              }
          };
      })


    return {
      setJoinUs,
      ...toRefs(state),
    };
  }
});
